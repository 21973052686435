import { Grid } from "@mui/material";
import React from "react";
import Project from "../components/Project";
import Personal from "../components/Personal";

import aandabusiness from "../images/aandabusiness.png";
import quantadevs from "../images/quantadevs.gif";
import campsite from "../images/campsite.png";
import personal from "../images/personal.gif";

import reactLogo from "../images/logos/reactjslogo.png";
import nodejslogo from "../images/logos/nodejslogo.png";
import bootstraplogo from "../images/logos/bootstraplogo.png";
import reduxlogo from "../images/logos/reduxlogo.png";
import firebase from "../images/logos/firebase.png";


import Title from "../components/Title";

const Work = () => {
    return (
        <section className="work">
            <Title>Projects And Colaborations</Title>
            <Grid container spacing={6} justify="center">
                <Project
                    image={quantadevs}
                    title={"QuantaDevs | Company Website"}
                    description={
                        "The company's main website includes a contact form and a specified request form for clients. "
                    }
                    technologies={[
                        { logo: reactLogo, name: "React JS" },
                        { logo: bootstraplogo, name: "Bootstrap" },
                        { logo: nodejslogo, name: "Node JS" },
                    ]}
                    demo={"https://www.quantadevs.com"}
                />
                <Project
                    image={aandabusiness}
                    title={"A&A Business Services | Company Website"}
                    description={
                        "A portfolio for an accounting firm with a dashboard for the admin to manage accounts and his clients to make payments and upload documents."
                    }
                    started="November 2021"
                    technologies={[
                        { logo: reactLogo, name: "React JS" },
                        { logo: bootstraplogo, name: "Bootstrap" },
                        { logo: nodejslogo, name: "Node JS" },
                        {
                            logo: "https://stripe.com/img/v3/home/twitter.png",
                            name: "Stripe JS",
                        },
                    ]}
                    demo={"https://www.aabusinessfl.com"}
                />
                <Project
                    image={campsite}
                    title={"Campsite | School Project"}
                    description={
                        "A website containing information about campsites where the user can rate them by posting comments or send a feedback form. This project was also built for mobile using React Native."
                    }
                    started="October 2021"
                    technologies={[
                        { logo: reactLogo, name: "React JS" },
                        { logo: bootstraplogo, name: "Bootstrap" },
                        { logo: reduxlogo,name: "Redux" },
                    ]}
                    personal={true}
                    code={"https://github.com/samomri/react-nucamp"}
                    demo={"http://52.91.200.15/home"}
                />
                <Personal
                    image={personal}
                    title={"Personal Projects"}
                    description={
                        "Personal projects ranging from a simple Todo App to a Chat App. Implementing user authentication and fetching, posting and deleting data"
                    }
                    technologies={[
                        { logo: reactLogo, name: "React JS" },
                        { logo: nodejslogo, name: "Node JS" },
                        { logo: firebase, name: "Firebase" },

                    ]}
                    code={"https://github.com/samomri"}
                />
            </Grid>
            <div id="contact" />
        </section>
    );
};

export default Work;
