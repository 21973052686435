import React from "react";
import Stars from "../components/Stars";

const Hero = () => {
    return (
        <div className="landing">
            <Stars />
            <div className="landing-heading">
                <div className="landing-heading-top">
                    <h2 className="landing-heading-top-title">
                        Hi!  <br /> I'm Sam.
                    </h2>
                    <p className="landing-heading-top-subtitle">A full stack web developer <br />I build creative and modern websites.
                    </p>
                </div>
                <a href="#contact" className="landing-heading-button">
                    Get In Touch
                </a>
               <a href="#about"><div class="scroll-down"></div></a> 
            </div>
            <div id="about" />
        </div>
    );
};

export default Hero;
